import React from 'react';
import {
	Box,
	Grid,
	Tooltip,
	Typography,
	Container,
	IconButton,
	Button,
	useTheme,
} from '@mui/material';
import {alpha} from '@mui/material/styles';
import {ArrowUpward, MailOutline, Phone, Place, LinkedIn} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {versionDate, versionNumber} from '../utils/Version';

const Footer = () => {
	const {t} = useTranslation();
	const theme = useTheme();

	const FooterLink = ({to, children}) => (
		<Link to={to} style={{textDecoration: 'none'}}>
			<Typography
				variant="body2"
				sx={{
					color: 'text.secondary',
					transition: 'all 0.2s ease',
					py: 0.5,
					fontFamily: 'Titillium Web',
					'&:hover': {
						color: '#4cae52',
						transform: 'translateX(5px)',
					},
				}}
			>
				{children}
			</Typography>
		</Link>
	);

	const ContactItem = ({icon: Icon, text, href, tooltip}) => (
		<Tooltip title={tooltip} placement="top">
			<Button
				component="a"
				href={href}
				startIcon={<Icon sx={{color: '#4cae52'}} />}
				sx={{
					justifyContent: 'flex-start',
					color: 'text.secondary',
					textTransform: 'none',
					py: 1,
					px: 1,
					fontFamily: 'Titillium Web',
					'&:hover': {
						bgcolor: alpha('#4cae52', 0.05),
						color: '#4cae52',
					},
				}}
			>
				{text}
			</Button>
		</Tooltip>
	);

	return (
		<Box
			component="footer"
			sx={{
				position: 'relative',
				bgcolor: '#ffffff',
				borderTop: '2px solid #4cae52',
				mt: 8,
			}}
		>
			<Container maxWidth="lg">
				{/* Main Footer Content */}
				<Grid container spacing={4} sx={{py: 6}}>
					{/* Company Info */}
					<Grid item xs={12} md={4}>
						<Box
							sx={{
								'& img': {
									transition: 'transform 0.3s ease',
									'&:hover': {
										transform: 'scale(1.02)',
									},
								},
							}}
						>
							<img
								src="https://storage.googleapis.com/redmanagement/redm_logo_xl_horizontal.png"
								alt="RED Management"
								style={{width: 200, marginBottom: 16}}
							/>
						</Box>

						<Typography
							variant="h6"
							sx={{
								color: '#4cae52',
								fontWeight: 600,
								mb: 2,
								fontFamily: 'Titillium Web',
							}}
						>
							RED Management AB
						</Typography>

						<Box sx={{mb: 3}}>
							<Typography
								variant="body2"
								sx={{
									color: 'text.secondary',
									fontFamily: 'Titillium Web',
									mb: 1,
								}}
							>
								{t('footer.cid')}
							</Typography>
							<Typography
								variant="body2"
								sx={{
									color: 'text.secondary',
									fontFamily: 'Titillium Web',
								}}
							>
								Vasagatan 15-17
								<br />
								111 20 Stockholm
							</Typography>
						</Box>

						<Tooltip title={t('footer.followlinkedin')} placement="top-start">
							<div>
								<ul className="social-network social-circle">
									<li>
										<a
											href="https://www.linkedin.com/company/red-management"
											className="icoLinkedin"
											title="Linkedin"
										>
											<i className="fa fa-linkedin"></i>
										</a>
									</li>
								</ul>
							</div>
						</Tooltip>
					</Grid>

					{/* Sitemap */}
					<Grid item xs={12} sm={6} md={4}>
						<Typography
							variant="h6"
							sx={{
								color: '#4cae52',
								fontWeight: 600,
								mb: 3,
								fontFamily: 'Titillium Web',
							}}
						>
							Sitemap
						</Typography>
						<Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
							<FooterLink to="/om-oss">{t('footer.about')}</FooterLink>
							<FooterLink to="/tjänster">{t('footer.services')}</FooterLink>
							<FooterLink to="/kontakt">{t('footer.contact')}</FooterLink>
							<FooterLink to="/nyheter">{t('footer.news')}</FooterLink>
							<FooterLink to="/projekt">{t('footer.projects')}</FooterLink>
							<FooterLink to="/karriär">{t('footer.careers')}</FooterLink>
						</Box>
					</Grid>

					{/* Contact Info */}
					<Grid item xs={12} sm={6} md={4}>
						<Typography
							variant="h6"
							sx={{
								color: '#4cae52',
								fontWeight: 600,
								mb: 3,
								fontFamily: 'Titillium Web',
							}}
						>
							{t('footer.contact')}
						</Typography>
						<Box sx={{display: 'flex', flexDirection: 'column'}}>
							<ContactItem
								icon={Phone}
								text={t('footer.phone')}
								href="tel:0046730858880"
								tooltip={t('footer.callus')}
							/>
							<ContactItem
								icon={MailOutline}
								text="info[a]redmanagement.se"
								href="mailto:info@redmanagement.se"
								tooltip={t('footer.sendemail')}
							/>
							<ContactItem
								icon={Place}
								text={t('footer.directions')}
								href="https://goo.gl/maps/sxpHZsCQoJrcaG7S7"
								tooltip={t('footer.directionstooltip')}
							/>
						</Box>
					</Grid>
				</Grid>
			</Container>

			{/* Bottom Bar */}
			<Box
				sx={{
					bgcolor: '#4cae52',
					color: '#FFFFFF',
					py: 2,
				}}
			>
				<Container maxWidth="lg">
					<Grid
						container
						spacing={2}
						alignItems="center"
						sx={{
							flexDirection: {xs: 'column', md: 'row'},
							textAlign: {xs: 'center', md: 'left'},
						}}
					>
						<Grid item xs={12} md={4}>
							<Typography variant="body2">2024 © Red Management AB</Typography>
						</Grid>

						<Grid item xs={12} md={4}>
							<Box sx={{display: 'flex', justifyContent: 'center', gap: 2}}>
								<Typography variant="body2">{versionNumber}</Typography>
								<Typography variant="body2">{versionDate}</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} md={4}>
							<Box
								sx={{
									display: 'flex',
									gap: 2,
									justifyContent: {xs: 'center', md: 'flex-end'},
									alignItems: 'center',
								}}
							>
								<Link
									to="/integritetspolicy"
									style={{
										color: '#FFFFFF',
										textDecoration: 'none',
										fontSize: '0.875rem',
										'&:hover': {
											textDecoration: 'underline',
										},
									}}
								>
									{t('footer.integritypolicy')}
								</Link>
								<Link
									to="/cookiepolicy"
									style={{
										color: '#FFFFFF',
										textDecoration: 'none',
										fontSize: '0.875rem',
										'&:hover': {
											textDecoration: 'underline',
										},
									}}
								>
									{t('footer.cookiepolicy')}
								</Link>
								<IconButton
									onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
									sx={{
										color: '#FFFFFF',
										bgcolor: alpha('#FFFFFF', 0.1),
										'&:hover': {
											bgcolor: alpha('#FFFFFF', 0.2),
										},
									}}
								>
									<ArrowUpward />
								</IconButton>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Box>
	);
};

export default Footer;
