import * as React from 'react';
import {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {AuthContext} from '../App';
import i18next from 'i18next';

import {alpha} from '@mui/material/styles';
// MUI Components
import {Box, IconButton, Button, Typography, Slide, Paper} from '@mui/material';

// Icons
import {
	ExitToApp,
	MenuOutlined,
	Close,
	ArrowRight,
	Group,
	Settings,
	Lock,
} from '@mui/icons-material';

// Assets
import redm_logo from '../assets/redm.png';
import sv_flag from '../assets/sv_flag.png';
import en_flag from '../assets/en_flag.png';

export default function ModernMobileMenu() {
	const [isOpen, setIsOpen] = React.useState(false);
	const {t} = useTranslation();
	const {state, dispatch} = useContext(AuthContext);
	const history = useHistory();

	const handleChangeLanguage = newLanguage => {
		localStorage.setItem('redmanagement-language', newLanguage);
		i18next.changeLanguage(newLanguage.split('-')[0], (err, t) => {
			if (err) return console.log('language changing failed.', err);
			window.location.reload();
		});
	};

	const handleNavigation = path => {
		setIsOpen(false);
		setTimeout(() => {
			history.push(path);
		}, 300); // Wait for animation to complete
	};

	const menuItems = [
		{path: '/tjänster', label: t('topbar.services')},
		{path: '/projekt', label: t('topbar.projects')},
		{path: '/nyheter', label: t('topbar.news')},
		{path: '/om-oss', label: t('topbar.about')},
		{path: '/karriär', label: t('topbar.career')},
		{path: '/kontakt', label: t('topbar.contact')},
	];

	return (
		<Box>
			{/* Header */}
			<Box
				sx={{
					position: 'sticky',
					top: 0,
					zIndex: 1200,
					backgroundColor: 'rgba(255, 255, 255, 0.98)',
					backdropFilter: 'blur(10px)',
					borderBottom: '1px solid',
					borderColor: 'rgba(0, 0, 0, 0.06)',
					padding: '12px 20px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					transition: 'all 0.3s ease',
					boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03)',
					'&:hover': {
						boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
					},
				}}
			>
				<IconButton
					onClick={() => setIsOpen(!isOpen)}
					sx={{
						color: '#4cae52',
						backgroundColor: alpha('#4cae52', 0.04),
						borderRadius: '12px',
						padding: '8px',
						transition: 'all 0.2s ease',
						'&:hover': {
							backgroundColor: alpha('#4cae52', 0.08),
							transform: 'scale(1.05)',
						},
						'&:active': {
							transform: 'scale(0.95)',
						},
					}}
					edge="start"
				>
					{isOpen ? <Close /> : <MenuOutlined />}
				</IconButton>

				<Box
					onClick={() => handleNavigation('/')}
					sx={{
						cursor: 'pointer',
						display: 'flex',
						alignItems: 'center',
						transform: 'translateX(-50%)',
						position: 'absolute',
						left: '50%',
						transition: 'transform 0.2s ease',
						'&:hover': {
							transform: 'translateX(-50%) scale(1.02)',
						},
						'&:active': {
							transform: 'translateX(-50%) scale(0.98)',
						},
					}}
				>
					<img
						src={redm_logo}
						alt="logo"
						style={{
							width: '100px',
							height: 'auto',
							display: 'block',
						}}
					/>
				</Box>

				<Box
					sx={{
						display: 'flex',
						gap: 1.5,
						padding: '4px',
						borderRadius: '12px',
						backgroundColor: alpha('#000', 0.02),
					}}
				>
					<IconButton
						onClick={() => handleChangeLanguage('sv-SE')}
						size="small"
						sx={{
							padding: '6px',
							borderRadius: '8px',
							backgroundColor: 'transparent',
							transition: 'all 0.2s ease',
							'&:hover': {
								backgroundColor: alpha('#000', 0.04),
								transform: 'translateY(-1px)',
							},
							'&:active': {
								transform: 'translateY(0px)',
							},
						}}
					>
						<Box
							sx={{
								width: '20px',
								height: '14px',
								overflow: 'hidden',
								borderRadius: '2px',
								boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
							}}
						>
							<img
								src={sv_flag}
								alt="Svenska"
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									display: 'block',
								}}
							/>
						</Box>
					</IconButton>

					<IconButton
						onClick={() => handleChangeLanguage('en-US')}
						size="small"
						sx={{
							padding: '6px',
							borderRadius: '8px',
							backgroundColor: 'transparent',
							transition: 'all 0.2s ease',
							'&:hover': {
								backgroundColor: alpha('#000', 0.04),
								transform: 'translateY(-1px)',
							},
							'&:active': {
								transform: 'translateY(0px)',
							},
						}}
					>
						<Box
							sx={{
								width: '20px',
								height: '14px',
								overflow: 'hidden',
								borderRadius: '2px',
								boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
							}}
						>
							<img
								src={en_flag}
								alt="English"
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									display: 'block',
								}}
							/>
						</Box>
					</IconButton>
				</Box>
			</Box>

			{/* Backdrop */}
			{isOpen && (
				<Box
					onClick={() => setIsOpen(false)}
					sx={{
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						bgcolor: 'rgba(0, 0, 0, 0.5)',
						zIndex: 1100,
						transition: 'opacity 0.3s',
					}}
				/>
			)}

			{/* Menu */}
			<Slide direction="down" in={isOpen} mountOnEnter unmountOnExit timeout={300}>
				<Paper
					elevation={4}
					sx={{
						position: 'fixed',
						top: '64px',
						left: 0,
						right: 0,
						maxHeight: 'calc(100vh - 64px)',
						overflowY: 'auto',
						zIndex: 1200,
						backgroundColor: '#fff',
					}}
				>
					<Box sx={{py: 2}}>
						{menuItems.map(item => (
							<Button
								key={item.path}
								onClick={() => handleNavigation(item.path)}
								sx={{
									width: '100%',
									padding: '12px 24px',
									display: 'flex',
									justifyContent: 'flex-start',
									textAlign: 'left',
									textTransform: 'none',
									'&:hover': {
										backgroundColor: 'rgba(0, 0, 0, 0.04)',
									},
								}}
							>
								<ArrowRight sx={{color: '#4cae52', mr: 2}} />
								<Typography variant="body1" color="textPrimary">
									{item.label}
								</Typography>
							</Button>
						))}

						<Box sx={{borderTop: 1, borderColor: 'divider', my: 2}} />

						{!state.isAuthenticated ? (
							<Button
								onClick={() => handleNavigation('/login')}
								sx={{
									width: '100%',
									padding: '12px 24px',
									display: 'flex',
									justifyContent: 'flex-start',
									textAlign: 'left',
									textTransform: 'none',
									'&:hover': {
										backgroundColor: 'rgba(0, 0, 0, 0.04)',
									},
								}}
							>
								<Lock sx={{color: '#4cae52', mr: 2}} />
								<Typography variant="body1" color="textPrimary">
									{t('topbar.login')}
								</Typography>
							</Button>
						) : (
							<>
								<Button
									onClick={() => handleNavigation('/internwebb')}
									sx={{
										width: '100%',
										padding: '12px 24px',
										display: 'flex',
										justifyContent: 'flex-start',
										textAlign: 'left',
										textTransform: 'none',
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.04)',
										},
									}}
								>
									<Group sx={{color: '#4cae52', mr: 2}} />
									<Typography variant="body1" color="textPrimary">
										Internwebb
									</Typography>
								</Button>

								<Button
									onClick={() =>
										handleNavigation('/kontakt/' + JSON.parse(localStorage.getItem('email')))
									}
									sx={{
										width: '100%',
										padding: '12px 24px',
										display: 'flex',
										justifyContent: 'flex-start',
										textAlign: 'left',
										textTransform: 'none',
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.04)',
										},
									}}
								>
									<Settings sx={{color: '#4cae52', mr: 2}} />
									<Typography variant="body1" color="textPrimary" noWrap>
										{JSON.parse(localStorage.getItem('firstName')) +
											' ' +
											JSON.parse(localStorage.getItem('lastName'))}
									</Typography>
								</Button>

								<Button
									onClick={() => {
										setIsOpen(false);
										dispatch({type: 'LOGOUT'});
									}}
									sx={{
										width: '100%',
										padding: '12px 24px',
										display: 'flex',
										justifyContent: 'flex-start',
										textAlign: 'left',
										textTransform: 'none',
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.04)',
										},
									}}
								>
									<ExitToApp sx={{color: '#4cae52', mr: 2}} />
									<Typography variant="body1" color="textPrimary">
										Logga ut
									</Typography>
								</Button>
							</>
						)}
					</Box>
				</Paper>
			</Slide>
		</Box>
	);
}
