import React, {Suspense, lazy} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {CssBaseline, Slide, CircularProgress} from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components that are always needed immediately can be imported normally
import ScrollToTop from '../utils/ScrollToTop';
import DesktopTopbar from './DesktopTopbar';
import MobileTopbarV2 from './MobileTopbarV2';
import Footer from './Footer';
import GoogleAnalytics from '../GoogleAnalytics';
import Loader from '../components/Loader/Loader';

// Lazy load all page components
const AboutPage = lazy(() => import('../pages/About/AboutPage'));
const CareersPage = lazy(() => import('../pages/Careers/CareersPage'));
const ContactPage = lazy(() => import('../pages/Contact/ContactPage'));
const CookiePolicyPage = lazy(() => import('../pages/CookiePolicy/CookiePolicyPage'));
const IntegrityPolicyPage = lazy(() => import('../pages/IntegrityPolicy/IntegrityPolicyPage'));
const PostsPage = lazy(() => import('../pages/News/PostsPage'));
const ServicesPage = lazy(() => import('../pages/Services/ServicesPage'));
const ProjectsPage = lazy(() => import('../pages/Project/ProjectsPage'));
const StartPage = lazy(() => import('../pages/Start/StartPage'));
const LoginPage = lazy(() => import('../pages/Login/LoginPage'));
const EditPostPage = lazy(() => import('../pages/News/EditPostPage'));
const EditProjectPage = lazy(() => import('../pages/Project/EditProjectPage'));
const ViewProjectPage = lazy(() => import('../pages/Project/ViewProjectPage'));
const ViewJobDetailPage = lazy(() => import('../pages/Careers/ViewJobDetailPage'));
const ViewPostPage = lazy(() => import('../pages/News/ViewPostPage'));
const ContactsPage = lazy(() => import('../pages/Contacts/ContactsPage'));
const EditServicePage = lazy(() => import('../pages/Services/EditServicePage'));
const ViewServicePage = lazy(() => import('../pages/Services/ViewServicePage'));
const IntranetPage = lazy(() => import('../pages/Intranet/IntranetPage'));
const ViewInternalPostPage = lazy(() => import('../pages/Intranet/ViewInternalPostPage'));
const EditInternalPostPage = lazy(() => import('../pages/Intranet/EditInternalPostPage'));
const NotfoundPage = lazy(() => import('../pages/NotfoundPage'));

// Loading component
const LoadingSpinner = () => (
	<div
		style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100vh',
		}}
	>
		<CircularProgress />
	</div>
);

function HideOnScroll(props) {
	const {children, window} = props;
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		threshold: 20,
	});
	return (
		<Slide appear={true} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}
const MainContent = ({isMobileScreen, props, children}) => (
	<>
		{isMobileScreen ? (
			<MobileTopbarV2 />
		) : (
			<HideOnScroll {...props}>
				<nav>
					<DesktopTopbar />
				</nav>
			</HideOnScroll>
		)}
		{children}
	</>
);
export const Main = props => {
	const isMobileScreen = useMediaQuery('(max-width:700px)');

	return (
		<BrowserRouter>
			<ScrollToTop />
			<CssBaseline />

			<Suspense fallback={isMobileScreen ? <Loader /> : null}>
				<MainContent isMobileScreen={isMobileScreen} props={props}>
					<Switch>
						<Route path="/cookiepolicy" component={CookiePolicyPage} />
						<Route path="/integritetspolicy" component={IntegrityPolicyPage} />
						<Route path="/login" component={LoginPage} />

						<Route path="/om-oss" component={AboutPage} />
						<Route path="/karriär" component={CareersPage} />
						<Route path="/ledigajobb/:job_id" component={ViewJobDetailPage} />
						<Route exact path="/kontakt" component={ContactsPage} />
						<Route path="/kontakt/:email" component={ContactPage} />

						<Route exact path="/nyheter" component={PostsPage} />
						<Route path="/nyheter/:post_id" component={ViewPostPage} />
						<Route path="/redigeranyhet/:post_id" component={EditPostPage} />

						<Route exact path="/internwebb" component={IntranetPage} />
						<Route path="/internwebb/:post_id" component={ViewInternalPostPage} />
						<Route path="/editinternal/:post_id" component={EditInternalPostPage} />

						<Route exact path="/tjänster" component={ServicesPage} />
						<Route path="/services/:item_id" component={EditServicePage} />
						<Route path="/tjänster/:url" component={ViewServicePage} />

						<Route exact path="/projekt" component={ProjectsPage} />
						<Route path="/projects/:project_id" component={EditProjectPage} />
						<Route path="/projekt/:url" component={ViewProjectPage} />

						<Route exact path="/" component={StartPage} />
						<Route component={NotfoundPage} />
					</Switch>
				</MainContent>
			</Suspense>
			<GoogleAnalytics />
			<Footer />
		</BrowserRouter>
	);
};

export default Main;
