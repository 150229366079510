import React, {useContext} from 'react';
import {
	AppBar,
	Box,
	Button,
	Container,
	IconButton,
	Toolbayr,
	Typography,
	useScrollTrigger,
	Slide,
	Toolbar,
} from '@mui/material';
import {alpha} from '@mui/material/styles';
import {ExitToApp, Group, Settings, SignalCellular3Bar} from '@mui/icons-material';
import {Link, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import {AuthContext} from '../App';
import {isAdmin} from '../utils/Authentication';
import {versionDate, versionNumber} from '../utils/Version';

// Import images
import en_flag from '../assets/en_flag.png';
import sv_flag from '../assets/sv_flag.png';

const HideOnScroll = ({children}) => {
	const trigger = useScrollTrigger();
	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
};

const NavLink = ({to, children}) => (
	<Link to={to} style={{textDecoration: 'none'}}>
		<Typography
			sx={{
				color: 'text.primary',
				px: 2,
				py: 1,
				fontFamily: 'Titillium Web',
				fontSize: '0.95rem',
				position: 'relative',
				transition: 'all 0.3s ease',
				'&:hover': {
					color: '#4cae52',
				},
				'&::after': {
					content: '""',
					position: 'absolute',
					bottom: 0,
					left: '50%',
					width: 0,
					height: '2px',
					bgcolor: '#4cae52',
					transition: 'all 0.3s ease',
				},
				'&:hover::after': {
					width: '80%',
					left: '10%',
				},
			}}
		>
			{children}
		</Typography>
	</Link>
);

const DesktopTopbar = () => {
	const {t} = useTranslation();
	const history = useHistory();
	const {state, dispatch} = useContext(AuthContext);

	const handleChangeLanguage = newLanguage => {
		localStorage.setItem('redmanagement-language', newLanguage);
		i18next.changeLanguage(newLanguage.split('-')[0], (err, t) => {
			if (err) return console.log('language changing failed.', err);
			window.location.reload();
		});
	};

	const handleLogout = e => {
		e.preventDefault();
		try {
			dispatch({type: 'LOGOUT'});
			history.push('/');
		} catch (error) {
			console.error('Logout error:', error);
			window.location.href = '/';
		}
	};

	return (
		<HideOnScroll>
			<AppBar
				position="fixed"
				elevation={0}
				sx={{
					bgcolor: 'rgba(255, 255, 255, 0.98)',
					backdropFilter: 'blur(10px)',
				}}
			>
				{/* Admin Bar */}
				{state.isAuthenticated && (
					<Box
						sx={{
							background: '#4cae52',
							color: 'white',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: {xs: '10px 1rem', sm: '10px', md: '10px 1rem'},

							alignContent: 'center',
						}}
					>
						<Container maxWidth="xl">
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								{/* Version Info & Analytics */}
								<Box sx={{display: 'flex', alignItems: 'center', gap: {xs: 0, sm: 1, md: 2}}}>
									<Typography variant="caption">{versionNumber}</Typography>
									<Typography variant="caption">{versionDate}</Typography>
									{isAdmin && (
										<Button
											size="small"
											startIcon={<SignalCellular3Bar />}
											onClick={() =>
												window.open('https://analytics.google.com/analytics/p339433877', '_blank')
											}
											sx={{
												color: '#FFFFFF',
												textTransform: 'none',
												px: 2,
												'&:hover': {
													bgcolor: alpha('#FFFFFF', 0.1),
												},
											}}
										>
											Google Analytics
										</Button>
									)}
								</Box>

								{/* User Actions */}
								<Box sx={{display: 'flex', gap: {sm: 0, md: 2}}}>
									<Button
										startIcon={<Group />}
										onClick={() => history.push('/internwebb')}
										sx={{
											color: '#FFFFFF',
											textTransform: 'none',
											'&:hover': {
												bgcolor: alpha('#FFFFFF', 0.1),
											},
										}}
									>
										Internwebb
									</Button>
									<Button
										startIcon={<Settings />}
										onClick={() =>
											history.push('/kontakt/' + JSON.parse(localStorage.getItem('email')))
										}
										sx={{
											color: '#FFFFFF',
											textTransform: 'none',
											'&:hover': {
												bgcolor: alpha('#FFFFFF', 0.1),
											},
										}}
									>
										{JSON.parse(localStorage.getItem('firstName')) +
											' ' +
											JSON.parse(localStorage.getItem('lastName'))}
									</Button>
									<Button
										startIcon={<ExitToApp />}
										onClick={handleLogout}
										sx={{
											color: '#FFFFFF',
											textTransform: 'none',
											'&:hover': {
												bgcolor: alpha('#FFFFFF', 0.1),
											},
										}}
									>
										Logga ut
									</Button>
								</Box>
							</Box>
						</Container>
					</Box>
				)}

				{/* Main Navbar */}
				<Box
					sx={{
						width: '100%',
						borderTop: state.isAuthenticated ? 'none' : '1rem solid #4cae52',
					}}
				>
					<Box
						sx={{
							maxWidth: '1300px',
							width: '100%',
							margin: '0 auto',
							px: {xs: 1, sm: 0, md: 4},
						}}
					>
						<Toolbar
							sx={{
								py: 1,
								display: 'flex',
								justifyContent: 'space-between',
								width: '100%',
							}}
						>
							{/* Logo */}
							<Link to="/">
								<Box
									sx={{
										transition: 'all 0.3s ease',
										'&:hover': {
											transform: 'scale(1.02)',
										},
										'& img': {
											width: {
												xs: '60px',
												sm: '80px',
												md: '140px',
											},
											height: 'auto',
											display: 'block',
										},
									}}
								>
									<img
										src="https://storage.googleapis.com/redmanagement/redm_logo_xl_horizontal.png"
										alt="RED Management"
									/>
								</Box>
							</Link>

							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: {xs: '0', sm: '0', md: 1},
								}}
							>
								<NavLink to="/tjänster">{t('topbar.services')}</NavLink>
								<NavLink to="/projekt">{t('topbar.projects')}</NavLink>
								<NavLink to="/nyheter">{t('topbar.news')}</NavLink>
								<NavLink to="/om-oss">{t('topbar.about')}</NavLink>
								<NavLink to="/karriär">{t('topbar.career')}</NavLink>
								<NavLink to="/kontakt">{t('topbar.contact')}</NavLink>

								{/* Language Switcher */}
								<Box sx={{display: 'flex', alignItems: 'center', ml: 2}}>
									<IconButton
										onClick={() => handleChangeLanguage('sv-SE')}
										sx={{
											p: 1,
											'&:hover': {transform: 'translateY(-1px)'},
											transition: 'transform 0.2s ease',
										}}
									>
										<img src={sv_flag} alt="Svenska" width="16" height="11" />
									</IconButton>
									<IconButton
										onClick={() => handleChangeLanguage('en-US')}
										sx={{
											p: 1,
											'&:hover': {transform: 'translateY(-1px)'},
											transition: 'transform 0.2s ease',
										}}
									>
										<img src={en_flag} alt="English" width="16" height="11" />
									</IconButton>
								</Box>

								{/* Login Button */}
								{!state.isAuthenticated && (
									<Button
										component={Link}
										to="/login"
										variant="outlined"
										sx={{
											ml: 2,
											color: '#4cae52',
											borderColor: '#4cae52',
											textTransform: 'none',
											fontFamily: 'Titillium Web',
											'&:hover': {
												borderColor: '#3d9341',
												bgcolor: alpha('#4cae52', 0.05),
											},
										}}
									>
										{t('topbar.login')}
									</Button>
								)}
							</Box>
						</Toolbar>
					</Box>
				</Box>
			</AppBar>
		</HideOnScroll>
	);
};

export default DesktopTopbar;
