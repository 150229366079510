import React from 'react';
import {Box, Typography} from '@mui/material';
import {versionDate, versionNumber} from '../../utils/Version';
import useMediaQuery from '@mui/material/useMediaQuery';

const styles = `
  .main-container-inverted {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    gap: 6rem;  /* Increased gap between elements */
    width: 100%;
    min-height: 100vh;
  }

  .loader-inverted-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader-inverted {
    width: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #4cae52;
    box-shadow: 0 0 0 0 rgba(76, 174, 82, 0.27);
    animation: l2 1.5s infinite linear;
    position: relative;
  }

  .loader-inverted:before,
  .loader-inverted:after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 rgba(76, 174, 82, 0.27);
    animation: inherit;
  }

  .loader-inverted:before {
    animation-delay: -0.5s;
  }

  .loader-inverted:after {
    animation-delay: -1s;
  }

  @keyframes l2 {
    100% {
      box-shadow: 0 0 0 60px rgba(76, 174, 82, 0);
    }
  }

  .text-container {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    gap: 1rem;
  }
`;

const Loader = ({size, loadingText}) => {
	return (
		<div
			style={{
				background: '#ffffff',
				minHeight: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				opacity: 0.9,
			}}
		>
			<style>{styles}</style>
			<div id="main-container-inverted">
				<div className="loader-inverted-container">
					<div className="loader-inverted"></div>
				</div>
				<Box
					sx={{
						textAlign: 'center',
						mt: 8, // Added significant margin top
					}}
				>
					<img
						src="https://storage.googleapis.com/redmanagement/redm_logo_xl_horizontal.png"
						alt="logo"
						style={{width: '140px'}}
					/>
				</Box>
			</div>
		</div>
	);
};

export default Loader;
